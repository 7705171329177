.grid-selection .grid-cursor circle {
    stroke-width: 6px;
    stroke: #fd7e14; /* orange in both darkly and flatly */
    fill: none;
}

.grid-selection .grid-selection-outline line {
    stroke-width: 10px;
    stroke: #3498db; /* cyan in both darkly and flatly */
    stroke-linecap: round;
}
