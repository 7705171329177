#game-board {
    position: absolute;
    display: block;
    margin: 0 auto;
    left: 0px;
    right: 0px;
    touch-action: none;
}

.darkly #game-board {
    fill: #ffffff;
    stroke: #ffffff;
}

.flatly #game-board {
    fill: #000000;
    stroke: #000000;
}