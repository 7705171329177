.grid-text text {
    text-anchor: middle;
    dominant-baseline: hanging;
    user-select: none;
}

text.text-outline {
    stroke-width: 3px;
    stroke-linecap: round;
}

text.text-fill {
    stroke-width: 0px;
    stroke: none;
}

.darkly text.text-outline {
    stroke: black;
}

.darkly text.text-fill {
    fill: #ffffff;
}

.flatly text.text-outline {
    stroke: #ffffff;
}

.flatly text.text-fill {
    fill: #000000;
}