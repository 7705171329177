#game-container {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    height: 100%;
}

.game-row {
    height: 100%;
}

.grid-column {
    position: relative;
    box-sizing: border-box;
    height: 100%;
}