.highlight { stroke: none; }

.numberpad button .highlight-1 { fill: #0000ff; }
.numberpad button .highlight-2 { fill: #ff0000; }
.numberpad button .highlight-3 { fill: #ff00ff; }
.numberpad button .highlight-4 { fill: #00ff00; }
.numberpad button .highlight-5 { fill: #00ffff; }
.numberpad button .highlight-6 { fill: #ffff00; }
.numberpad button .highlight-7 { fill: #ff8000; }
.numberpad button .highlight-8 { fill: #0060c0; }
.numberpad button .highlight-9 { fill: #ff8080; }

.highlight-10 { fill: #c0c0c0; }
.highlight-11 { fill: #808080; }
.highlight-12 { fill: #404040; }

.darkly .highlight-1 { fill: #000080; }
.darkly .highlight-2 { fill: #800000; }
.darkly .highlight-3 { fill: #800080; }
.darkly .highlight-4 { fill: #006600; }
.darkly .highlight-5 { fill: #005050; }
.darkly .highlight-6 { fill: #808000; }
.darkly .highlight-7 { fill: #804000; }
.darkly .highlight-8 { fill: #003060; }
.darkly .highlight-9 { fill: #803040; }

.flatly .highlight-1 { fill: #8080ff; }
.flatly .highlight-2 { fill: #ff3333; }
.flatly .highlight-3 { fill: #ff80ff; }
.flatly .highlight-4 { fill: #60ff60; }
.flatly .highlight-5 { fill: #40ffff; }
.flatly .highlight-6 { fill: #ffff60; }
.flatly .highlight-7 { fill: #ffa040; }
.flatly .highlight-8 { fill: #99ccff; }
.flatly .highlight-9 { fill: #ff60c0; }
