.numberpad, .highlightPad {
    display: flex;
    flex-direction: column;
}

.numberpad-row, .highlightPad-row {
    display: flex;
    flex-direction: row;
}

.numberpad-row button, .highlightPad-row button {
    margin: 0.1em;
    width: 3em;
    height: 3em;
}