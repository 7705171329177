@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&amp;display=swap);

html, body {
    height: 100%;
}

#root, #theme-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}
#game-container {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    height: 100%;
}

.game-row {
    height: 100%;
}

.grid-column {
    position: relative;
    box-sizing: border-box;
    height: 100%;
}
#game-board {
    position: absolute;
    display: block;
    margin: 0 auto;
    left: 0px;
    right: 0px;
    touch-action: none;
}

.darkly #game-board {
    fill: #ffffff;
    stroke: #ffffff;
}

.flatly #game-board {
    fill: #000000;
    stroke: #000000;
}
.darkly .grid-background {
    fill: #202020;
    stroke: #ffffff;
}

.flatly .grid-background {
    fill: #ffffff;
    stroke: #000000;
}

.grid-background {
    stroke-width: 5px;
}
.grid-selection .grid-cursor circle {
    stroke-width: 6px;
    stroke: #fd7e14; /* orange in both darkly and flatly */
    fill: none;
}

.grid-selection .grid-selection-outline line {
    stroke-width: 10px;
    stroke: #3498db; /* cyan in both darkly and flatly */
    stroke-linecap: round;
}

.arrow line, .arrow path, .arrow circle {
    stroke-width: 7px;
    fill: none;
    stroke-linecap: round;
    stroke: #a08060;
}

.darkly .arrow-root {
    fill: #302010;
}

.flatly .arrow-root {
    fill: #ffeedd;
}

.killerCage .killerCage-label text {
    font-size: 20px;
    font-weight: bold;
    text-anchor: start;
}

.killerCage-outline line {
    stroke-dasharray: 5 5;
}
.grid-text text {
    text-anchor: middle;
    dominant-baseline: hanging;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

text.text-outline {
    stroke-width: 3px;
    stroke-linecap: round;
}

text.text-fill {
    stroke-width: 0px;
    stroke: none;
}

.darkly text.text-outline {
    stroke: black;
}

.darkly text.text-fill {
    fill: #ffffff;
}

.flatly text.text-outline {
    stroke: #ffffff;
}

.flatly text.text-fill {
    fill: #000000;
}
.darkly .thermometer path {
    stroke: #404040;
}

.darkly .thermometer circle {
    fill: #404040;
}

.flatly .thermometer path {
    stroke: #cccccc;
}

.flatly .thermometer circle {
    fill: #cccccc;
}
.gridlines-grid line {
    fill: none;
    stroke-width: 1px;
}

.gridlines-regions line {
    fill: none;
    stroke-width: 3px;
}
svg text {
    font-family: Noto Sans;
}

.given-digit text {
    font-size: 70px;
    font-weight: bold;
}

.placed-digit text {
    font-size: 70px;
    font-weight: normal;
}

.candidates text, .hints text {
    font-size: 20px;
}

.darkly .placed-digit text.text-fill
{
    fill: #3498db;
}

.flatly .placed-digit text.text-fill
{
    fill: #22628d;
}


.grid-conflicts line {
    stroke-width: 8px;
    stroke: red;
    stroke-linecap: round;
}

.grid-conflicts line.conflict-connector {
    stroke-width: 4px;
    stroke-dasharray: 4 4;
    stroke-linecap: butt;
}

.highlight { stroke: none; }

.numberpad button .highlight-1 { fill: #0000ff; }
.numberpad button .highlight-2 { fill: #ff0000; }
.numberpad button .highlight-3 { fill: #ff00ff; }
.numberpad button .highlight-4 { fill: #00ff00; }
.numberpad button .highlight-5 { fill: #00ffff; }
.numberpad button .highlight-6 { fill: #ffff00; }
.numberpad button .highlight-7 { fill: #ff8000; }
.numberpad button .highlight-8 { fill: #0060c0; }
.numberpad button .highlight-9 { fill: #ff8080; }

.highlight-10 { fill: #c0c0c0; }
.highlight-11 { fill: #808080; }
.highlight-12 { fill: #404040; }

.darkly .highlight-1 { fill: #000080; }
.darkly .highlight-2 { fill: #800000; }
.darkly .highlight-3 { fill: #800080; }
.darkly .highlight-4 { fill: #006600; }
.darkly .highlight-5 { fill: #005050; }
.darkly .highlight-6 { fill: #808000; }
.darkly .highlight-7 { fill: #804000; }
.darkly .highlight-8 { fill: #003060; }
.darkly .highlight-9 { fill: #803040; }

.flatly .highlight-1 { fill: #8080ff; }
.flatly .highlight-2 { fill: #ff3333; }
.flatly .highlight-3 { fill: #ff80ff; }
.flatly .highlight-4 { fill: #60ff60; }
.flatly .highlight-5 { fill: #40ffff; }
.flatly .highlight-6 { fill: #ffff60; }
.flatly .highlight-7 { fill: #ffa040; }
.flatly .highlight-8 { fill: #99ccff; }
.flatly .highlight-9 { fill: #ff60c0; }

.numberpad, .highlightPad {
    display: flex;
    flex-direction: column;
}

.numberpad-row, .highlightPad-row {
    display: flex;
    flex-direction: row;
}

.numberpad-row button, .highlightPad-row button {
    margin: 0.1em;
    width: 3em;
    height: 3em;
}
