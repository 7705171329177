.arrow line, .arrow path, .arrow circle {
    stroke-width: 7px;
    fill: none;
    stroke-linecap: round;
    stroke: #a08060;
}

.darkly .arrow-root {
    fill: #302010;
}

.flatly .arrow-root {
    fill: #ffeedd;
}
