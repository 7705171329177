@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&amp;display=swap');

svg text {
    font-family: Noto Sans;
}

.given-digit text {
    font-size: 70px;
    font-weight: bold;
}

.placed-digit text {
    font-size: 70px;
    font-weight: normal;
}

.candidates text, .hints text {
    font-size: 20px;
}

.darkly .placed-digit text.text-fill
{
    fill: #3498db;
}

.flatly .placed-digit text.text-fill
{
    fill: #22628d;
}

