.grid-conflicts line {
    stroke-width: 8px;
    stroke: red;
    stroke-linecap: round;
}

.grid-conflicts line.conflict-connector {
    stroke-width: 4px;
    stroke-dasharray: 4 4;
    stroke-linecap: butt;
}
