.darkly .grid-background {
    fill: #202020;
    stroke: #ffffff;
}

.flatly .grid-background {
    fill: #ffffff;
    stroke: #000000;
}

.grid-background {
    stroke-width: 5px;
}