.darkly .thermometer path {
    stroke: #404040;
}

.darkly .thermometer circle {
    fill: #404040;
}

.flatly .thermometer path {
    stroke: #cccccc;
}

.flatly .thermometer circle {
    fill: #cccccc;
}